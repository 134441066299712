import request from "@/utils/request"

//查询渠道商分页查询
export function channelPageList(data) {
    return request({
        url: '/channelMassage/listPage',
        method: 'get',
        params: data
    })
}

//新增渠道方
export function channelAdd(data) {
    return request({
        url: '/channelMassage/save',
        method: 'post',
        data
    })
}

//编辑渠道方
export function channelEdit(data) {
    return request({
        url: '/channelMassage/update',
        method: 'put',
        data
    })
}

//删除渠道方
export function channelDelete(data) {
    return request({
        url: '/channelMassage/listById',
        method: 'delete',
        params: data
    })
}