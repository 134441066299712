<template>
  <div class="cascaderArea">
    <el-select
      v-if="defaultValue"
      v-model="select.country"
      placeholder="请选择国家"
      @change="changeCountry"
      class="middle-select"
    >
      <el-option value label="不限"></el-option>
      <el-option
        v-for="item in list.countrys"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      ></el-option>
    </el-select>
    <el-select
      v-model="select.province"
      placeholder="请选择省份"
      @change="changeProvince"
      class="middle-select"
    >
      <el-option value label="不限"></el-option>
      <el-option
        v-for="item in list.provinces"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      ></el-option>
    </el-select>
    <el-select
      v-model="select.city"
      placeholder="请选择地区"
      @change="changeCity"
      class="middle-select"
    >
      <el-option value label="不限"></el-option>
      <el-option
        v-for="item in list.citys"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { getCity } from "@/api/common.js";
export default {
  props: {
    country: {
      type: String,
    },
    province: {
      type: String,
    },
    city: {
      type: String,
    },
    defaultValue: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      list: {
        countrys: [],
        provinces: [],
        citys: [],
      },
      select: {
        country: null,
        province: null,
        city: null,
      },
    };
  },
  watch: {
    country(newVal) {
      if (newVal) {
        this.search();
      }
    },
    select: {
      handler(newVal) {
        if (newVal) {
          this.$emit("getArea", newVal);
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.search();
  },
  methods: {
    async search() {
      this.list.countrys = sessionStorage.getItem("area");

      if (!this.list.countrys) {
        const { data: res } = await getCity();
        if (res.code === 10200) {
          sessionStorage.setItem("area", JSON.stringify(res.data));
        }
      }
      this.list.countrys = JSON.parse(sessionStorage.getItem("area"));
      if (this.country) {
        this.select.country = this.country;
        this.changeCountry(this.country);
      }
      if (this.province) {
        this.select.province = this.province;
        this.changeProvince(this.province);
      }
      if (this.city) {
        this.select.city = this.city;
        this.changeCity(this.city);
      }
    },
    changeCountry(val) {
      this.list.provinces = [];
      this.list.citys = [];
      this.list.countrys.forEach((item) => {
        if (item.name === val) {
          this.list.provinces = item.cityResults;
        }
      });

      this.select.province = "";

      this.select.city = "";
    },
    changeProvince(val) {
      this.list.citys = [];

      this.list.provinces.forEach((item) => {
        if (item.name === val) {
          this.list.citys = item.cityResults;
        }
      });

      this.select.city = "";
    },
    changeCity(val) {
      this.$emit("searchCity", val);
    },
    clear() {
      this.select = {
        country: "",
        province: "",
        city: "",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.cascaderArea {
  .el-select {
    margin-right: 10px;
  }

  /deep/.el-input {
    width: 120px;
  }

  .middle-select,
  .middle-input {
    width: 120px !important;
  }
}
</style>