<template>
  <div class="channel">
    <el-card>
      <el-form :inline="true" :model="queryInfo">
        <el-form-item label="渠道方简称：">
          <el-input
            clearable
            v-model="queryInfo.channelName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">确定</el-button>
          <el-button type="primary" @click="addClick">+新建</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <el-table
        :data="data"
        stripe
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F0F0FF', color: '#252631' }"
      >
        <el-table-column
          prop="channelName"
          label="渠道方简称"
          align="center"
        ></el-table-column>
        <el-table-column label="所在地区" align="center">
          <template v-slot="{ row }">
            <span>{{ row.countries }}{{ row.provinces }}{{ row.city }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="channelType"
          label="渠道方类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="introduction"
          label="渠道简介"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="principal"
          label="负责人"
          align="center"
        ></el-table-column>
        <el-table-column prop="sex" label="性别" align="center">
          <template v-slot="{ row }">
            <span v-if="row.sex === 0">男</span>
            <span v-else>女</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="phone"
          label="联系方式"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="添加时间"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <el-button type="text" @click="editClick(row)">编辑</el-button>
            <el-button
              style="color: #f37c7c"
              type="text"
              @click="deleteClick(row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="special_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[30, 50, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>
    <el-dialog
      :title="dialogType ? '编辑渠道方' : '添加渠道方'"
      :visible.sync="dialogVisible"
      width="40%"
      @close="handleClose"
    >
      <el-form
        :model="queryInfoTan"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
      >
        <el-form-item prop="channelName" label="渠道方简称">
          <el-input v-model="queryInfoTan.channelName"></el-input>
        </el-form-item>
        <el-form-item label="渠道方全称">
          <el-input v-model="queryInfoTan.channelFullName"></el-input>
        </el-form-item>
        <el-form-item prop="channelType" label="渠道类型">
          <el-select
            v-model="queryInfoTan.channelType"
            clearable
            placeholder="请选择渠道类型"
          >
            <el-option label="企业" value="企业"></el-option>
            <el-option label="其他机构" value="其他机构"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在地区">
          <city-select
            :country="queryInfoTan.countries"
            :province="queryInfoTan.provinces"
            :city="queryInfoTan.city"
            @getArea="searchArae"
            class="CascaderArea"
          />
        </el-form-item>
        <el-form-item label="渠道简介">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入内容"
            v-model="queryInfoTan.introduction"
          />
        </el-form-item>
        <el-form-item label="负责人" prop="principal">
          <el-input v-model="queryInfoTan.principal"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="queryInfoTan.sex">
            <el-radio :label="0">男</el-radio>
            <el-radio :label="1">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联系方式" prop="phone">
          <el-input v-model="queryInfoTan.phone"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  channelPageList,
  channelAdd,
  channelEdit,
  channelDelete,
} from "@/api/channel.js";
import { pagination } from "@/mixins/pagination";
import CitySelect from "../../../components/CitySelect.vue";
const defaultQueryInfo = Object.freeze({
  channelName: "", //渠道商名称
  pageNumber: 0, // 页码
  pageSize: 30, //每页显示个数
});
const defaultQueryInfoTan = Object.freeze({
  channelFullName: "",
  channelName: "",
  channelType: "",
  city: "",
  countries: "中国",
  introduction: "",
  phone: "",
  principal: "",
  provinces: "",
  sex: "",
});
export default {
  components: { CitySelect },
  name: "Channel",
  mixins: [pagination],
  data() {
    return {
      dialogType: 0, // 面板类型 0 添加 1 编辑
      rules: {
        channelName: [
          { required: true, message: "请输入渠道方简称", trigger: "blur" },
        ],
        channelType: [
          { required: true, message: "请选择渠道类型", trigger: "blur" },
        ],
        principal: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
      },
      dialogVisible: false,
      queryInfo: { ...defaultQueryInfo },
      data: [], //数据列表
      queryInfoTan: { ...defaultQueryInfoTan },
    };
  },
  created() {
    this.search();
  },
  methods: {
    //新建渠道方
    addClick() {
      this.dialogType = 0;
      this.dialogVisible = true;
      Object.assign(this.queryInfoTan, defaultQueryInfoTan);
    },
    //点击确定新增或者编辑
    submitClick() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res;
          if (this.dialogType) {
            //编辑
            res = await channelEdit(this.queryInfoTan);
            if (res.data.code === 10200) {
              this.$message.success("编辑成功！");
              this.search();
              this.dialogVisible = false;
              Object.assign(this.queryInfoTan, defaultQueryInfoTan);
            }
          } else {
            //新增
            res = await channelAdd(this.queryInfoTan);
            if (res.data.code === 10200) {
              this.$message.success("新增成功！");
              this.search();
              this.dialogVisible = false;
              Object.assign(this.queryInfoTan, defaultQueryInfoTan);
            }
          }
        }
      });
    },
    //地点选择发射
    searchArae(val) {
      console.log(val);
      this.queryInfoTan.countries = val.country;
      this.queryInfoTan.provinces = val.province;
      if (
        val.city != "北京" &&
        val.city != "天津" &&
        val.city != "上海" &&
        val.city != "重庆"
      ) {
        this.queryInfoTan.city = val.city;
      }
    },
    //关闭弹窗
    handleClose() {
      this.dialogType = false;
      this.$refs.ruleForm.resetFields();
      Object.assign(this.queryInfoTan, defaultQueryInfoTan);
    },
    //编辑
    editClick(row) {
      Object.assign(this.queryInfoTan, row);
      this.dialogVisible = true;
      this.dialogType = 1;
    },
    //删除
    deleteClick(row) {
      this.$confirm(`确认删除${row.channelName}吗 ?`, {
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await channelDelete({ id: row.id });

          if (res.code === 10200) {
            this.$messageSuccess(`${row.channelName}删除成功`);
            this.search();
          }
        })
        .catch(() => {});
    },
    //获取数据
    async search() {
      const { data: res } = await channelPageList(this.queryInfo);
      if (res.code === 10200) {
        this.data = res.data.data;
        this.total = res.data.pageTotal;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.channel {
  width: 100%;
  overflow: auto;
  .el-button--primary {
    background-color: #8483d0;
    border-color: #8483d0;
  }

  .special_page {
    margin-top: 30px;
  }
}
</style>